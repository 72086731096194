<template>
  <v-dialog
    v-model="dialogHomens"
    @click:outside="$emit('update:dialogHomens', false)"
    @keydown.esc="$emit('update:dialogHomens', false)"
    max-width="1200px"
    scrollable
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="accent">
        Persona 1 - Homens
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('update:dialogHomens', false)">
          mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="py-4">
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          dense
          hide-details
        ></v-text-field>

        <v-data-table
          :headers="headers"
          :search="search"
          :items="data"
          :loading="loading"
          :items-per-page="10"
          class="data-tables data-tables__row-click mt-4"
          @click:row="goToUsuario"
        >
          <!-- Status -->
          <template v-slot:item.status="{ item }">
            {{ item.status | status }}
          </template>

          <!-- Created At -->
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFormat("dd/MM/yyyy") }}
          </template>

          <!-- User Agent -->
          <template v-slot:item.user_agent="{ item }">
            {{ item.user_agent | excerpt(20) }}
          </template>

          <!-- Celular -->
          <template v-slot:item.celular="{ item }">
            <div class="d-flex align-center justify-start">
              <span>{{ item.celular }}</span>

              <v-btn x-small icon class="ml-1" v-if="item.celular">
                <v-icon
                  small
                  @click.stop="goToWhats(item.celular)"
                  color="green"
                >
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </div>
          </template>

          <!-- Cadastro -->
          <template v-slot:item.cadastro="{ item }">
            <v-icon
              :color="item.cadastro ? 'green darken-2' : 'grey lighten-1'"
            >
              {{
                item.cadastro
                  ? "mdi-checkbox-marked-circle"
                  : "mdi-close-circle"
              }}
            </v-icon>
          </template>

          <!-- Checkin -->
          <template v-slot:item.checkin="{ item }">
            <v-icon :color="item.checkin ? 'green darken-2' : 'grey lighten-1'">
              {{
                item.checkin ? "mdi-checkbox-marked-circle" : "mdi-close-circle"
              }}
            </v-icon>
          </template>

          <!-- Vip -->
          <template v-slot:item.vip="{ item }">
            <v-icon :color="item.vip ? 'green darken-2' : 'grey lighten-1'">
              {{ item.vip ? "mdi-checkbox-marked-circle" : "mdi-close-circle" }}
            </v-icon>
          </template>

          <!-- Maestria -->
          <template v-slot:item.maestria="{ item }">
            <v-icon
              :color="item.maestria ? 'green darken-2' : 'grey lighten-1'"
            >
              {{
                item.maestria
                  ? "mdi-checkbox-marked-circle"
                  : "mdi-close-circle"
              }}
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogLead
      v-if="dialogLeads"
      :dialogLeads.sync="dialogLeads"
      :item="usuario"
      @reload-data="getHomens"
    />
  </v-dialog>
</template>

<script>
import { fetchHomens } from "@/api/reports/users.js";

export default {
  name: "Relatorios_Users_Homens",

  components: {
    DialogLead: () => import("@/views/staff/leads/components/DialogLead.vue"),
  },

  props: {
    dialogHomens: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      data: [],
      search: "",
      dialogLeads: false,
      usuario: {},
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "documento",
          value: "documento",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "celular",
          width: "120px",
          sortable: false,
          value: "celular",
        },
        {
          text: "Cadastrado",
          align: "center",
          width: "80px",
          sortable: false,
          value: "cadastro",
        },
        {
          text: "Checkin",
          value: "checkin",
          width: "80px",
          sortable: false,
          align: "center",
        },
        {
          text: "Vip",
          value: "vip",
          width: "80px",
          sortable: false,
          align: "center",
        },
        {
          text: "Maestria",
          value: "maestria",
          width: "80px",
          sortable: false,
          align: "center",
        },
      ];
    },
  },

  methods: {
    goToWhats(telefone) {
      let url = "https://api.whatsapp.com/send?phone=";

      let telefone_sanatizado = telefone.replace(/\D/g, "");

      if (telefone_sanatizado.substr(0, 3) == "595") {
        url = url + telefone_sanatizado;
        window.open(url, "_blank");
      } else if (telefone_sanatizado.substr(0, 2) == "55") {
        url = url + telefone_sanatizado;
        window.open(url, "_blank");
      } else {
        url = url + "55" + telefone_sanatizado;
        window.open(url, "_blank");
      }
    },

    goToUsuario(item) {
      this.usuario = { ...item };
      this.dialogLeads = true;
    },

    async getHomens() {
      this.data = await fetchHomens();
    },
  },

  async mounted() {
    this.loading = true;
    this.getHomens();
    this.loading = false;
  },
};
</script>

<style></style>
